
import { Vue, Options } from 'vue-class-component'
import { ElMessage } from 'element-plus'

import axios from '@/plugins/axios'
interface Result {
  data: []
}
@Options({
  components: {}
})
export default class AccountDetailPage extends Vue {
  refForm = 'formUser'
  accountInfo = {}
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  rules = {
    email: [
      {
        required: true,
        message: 'Please input email address',
        trigger: 'blur'
      },
      {
        type: 'email',
        message: 'Please input correct email address',
        trigger: ['blur', 'change']
      }
    ],
    firstName: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ],
    lastName: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ],
    phoneNumber: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ],
    address1: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ],
    companyName: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ],
    city: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ],
    stateRegion: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ],
    zipCode: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ],
    country: [
      {
        required: true,
        message: 'Please input require',
        trigger: 'blur'
      }
    ]
  }
  async created() {
    this.getAccounInfo()
  }
  async clickUpdateAccountInfo(accountInfo: any) {
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    await this.$store.dispatch('setLoading', true, { root: true })
    await axios
      .put('user/account/info', accountInfo, {
        headers: this.headers
      })
      .then(function () {
        ElMessage.success('Save successfully !!!')
      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error('Something wrong !!!')
      })
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getAccounInfo() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result = {
      data: []
    }
    res = await axios.get('user/account/info', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    if (res) {
      this.accountInfo = res
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
}
